@if (type() === 'apploader') {
	<button
		id="button-unstyled app-loader"
		[ngClass]="{ show: show, loading: style === 'loading', running: style === 'running' }"
		(click)="clicked($event)">
		<div class="loader-container">
			<svg class="circular" width="48px" height="48px">
				<circle class="path-bg" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke="#eeeeee" />
				<circle class="path" cx="24" cy="24" r="22" fill="none" stroke-width="4" stroke-miterlimit="10" stroke="#F96D00" />
			</svg>
		</div>
	</button>
} @else {
	<div id="inline-loader" [ngClass]="{ show: show }">
		<svg class="circular" viewBox="0 0 24 24">
			>
			<circle class="path-bg" cx="12" cy="12" r="11" fill="none" stroke-width="2" stroke="#eeeeee" />
			<circle class="path" cx="12" cy="12" r="11" fill="none" stroke-width="2" stroke-miterlimit="5" stroke="#F96D00" />
		</svg>
	</div>
}
