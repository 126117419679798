import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, input, model, OnDestroy, OnInit } from '@angular/core';
import { UiService } from 'app/_shared/services/ui.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass],
})
export class LoaderComponent implements OnInit, OnDestroy {
	type = input<'apploader' | 'inline'>('inline');
	show = model(true);

	style: 'loading' | 'running' = 'running';

	showedAt: number | undefined;

	private onDestroy$: Subject<void> = new Subject();

	constructor(
		private uiSrv: UiService,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		if (this.type() === 'apploader') {
			this.uiSrv
				.loaderOvVisible()
				.pipe(
					takeUntil(this.onDestroy$),
					// tap(v => console.log(v)),
					tap(({ show, style }) => {
						this.show.set(show);
						this.style = style ? style : 'running';
						if (this.show() === true) {
							this.showedAt = Date.now();
						}
					}),
					tap(() =>
						setTimeout(() => {
							try {
								this.cd.detectChanges();
							} catch {
								return;
							}
						}, 0)
					)
				)
				.subscribe();
		}
	}

	ngOnDestroy(): void {
		this.onDestroy$.next();
		this.onDestroy$.complete();
	}

	clicked(event: Event) {
		event.stopPropagation();
		event.preventDefault();

		if (Date.now() - (this.showedAt ?? 0) > 10000) {
			this.uiSrv.addToastMsgToQueue({ msg: 'Qualcosa non è andata per il verso giusto', isErrorMsg: true });
			this.show.set(false);
		}
	}
}
